const mobileMenuRef = document.querySelector('[data-menu]');
const anchorLinks = document.querySelectorAll('a[href^="#"]');

anchorLinks.forEach(link => {
  link.addEventListener('click', function (e) {
    e.preventDefault();

    // mobileMenuRef.classList.remove('is-hidden');
    mobileMenuRef.classList.add('is-hidden');

    const targetId = this.getAttribute('href').substring(1);
    const targetSection = document.getElementById(targetId);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  });
});
